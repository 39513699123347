import React, { FC } from 'react'

interface IProps {
    state: number
}

/**
* @author
* @function @Battery
**/

const DANGER_THRESHOLD = 10;

export const Battery: FC<IProps> = (props) => {
    let icon = 'bi-battery-full';
    if (props.state < 66) {
        icon = 'bi-battery-half'
    } else if (props.state < 33) {
        icon = 'bi-battery'
    }

    const rounded = Math.round(props.state);

    return (
        <div className='center' style={{
            fontSize: 'xxx-large',
            color: props.state < DANGER_THRESHOLD ? 'red' : undefined
        }}>

            <i className={`bi ${icon} me-3`} />
            <span style={{ fontSize: 'xx-large' }} >{rounded}%</span>
        </div>
    )
}
